import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Language/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import LangTable from 'components/Web_User_Interface/1440p_Series/System/Language/langTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Language",
  "path": "/Web_User_Interface/1440p_Series/System/Language/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.",
  "image": "./WebUI_1440p_SearchThumb_System_Language.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_Language.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Language' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.' image='/images/Search/WebUI_1440p_SearchThumb_System_Language.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_Language.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Sprache/' locationFR='/fr/Web_User_Interface/1440p_Series/System/Language/' crumbLabel="Language" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63714eef2096e89667057b28d14d83ca/d9199/1440p_Settings_System_Language.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACPklEQVQoz2WQS2sTYRRA8y98gFCVvF+0NJP3TJLJJG1nMpn5JtMk0giFilQU21JracRFtRY3FdRSFyLYrRqpiBQqLlRcFLp15285kvRh1cXhwl0czr2eeDxOJBIl4L1EstrGfrCHvtJjYuU9Y8s9ykvv0P6hvNSj1u3RWt+jNnWToQvnOXP2HEMXh/CEw2H6+L2XSasmne5LWotPad/ZpLn4nMbCs/+Ze0JrfoPO8haaeYV4JEosNkw0Gj8ShkIEAkESoyO0HJNJu4ZrGzSFiWvrWEYFuzaGXRsfINw2onkV4bioBZV8TqVYqJBJ5/8UBgIBJClJq93GabjYwkE4DkI42E4D2zIPhZaJcXsDs/sa88Yq45UKxUIJRVZJJbOnTvb7kSQJ13WxbRshxGDag2khOrM0rndpTC8wZxis6DqzRg0hqmhqAXkgzP0tTCQSOI5DvV4fyBxHDMTCquPM3D383+wa97RxNrQJlow67qRGRVVQ8iUy6aPCUCh0QiwWIxaNksvKWJaBI6qMVTUqJZliLoWuyUzPdHix/YrtrUdMWQpFRaGiFslmMnhOy/p/7Jf2SUoZVFVDn1CR83mEqbN46xoP19b58vkTPw9+8Gunx+p0E1nOUVFLpFNZPH3JcWUwGBzQ32XTeUrFMmVVw9J1Nh/f58Obbb7u73PwbZfvO2852P3I1vwMhiZTUNTDk/uCSCRyUngs9Pn8R/gYjkeRM0mkxCi5XJZCPo2cSVEoKkgjMcJBH16vH6/Xy2+Mm31+Gr0sBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63714eef2096e89667057b28d14d83ca/e4706/1440p_Settings_System_Language.avif 230w", "/en/static/63714eef2096e89667057b28d14d83ca/d1af7/1440p_Settings_System_Language.avif 460w", "/en/static/63714eef2096e89667057b28d14d83ca/7f308/1440p_Settings_System_Language.avif 920w", "/en/static/63714eef2096e89667057b28d14d83ca/542bc/1440p_Settings_System_Language.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63714eef2096e89667057b28d14d83ca/a0b58/1440p_Settings_System_Language.webp 230w", "/en/static/63714eef2096e89667057b28d14d83ca/bc10c/1440p_Settings_System_Language.webp 460w", "/en/static/63714eef2096e89667057b28d14d83ca/966d8/1440p_Settings_System_Language.webp 920w", "/en/static/63714eef2096e89667057b28d14d83ca/e46b2/1440p_Settings_System_Language.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63714eef2096e89667057b28d14d83ca/81c8e/1440p_Settings_System_Language.png 230w", "/en/static/63714eef2096e89667057b28d14d83ca/08a84/1440p_Settings_System_Language.png 460w", "/en/static/63714eef2096e89667057b28d14d83ca/c0255/1440p_Settings_System_Language.png 920w", "/en/static/63714eef2096e89667057b28d14d83ca/d9199/1440p_Settings_System_Language.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63714eef2096e89667057b28d14d83ca/c0255/1440p_Settings_System_Language.png",
              "alt": "Web User Interface - 1440p Series - System Language",
              "title": "Web User Interface - 1440p Series - System Language",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can choose the language of the web user interface. The web user interface will reload after you click Submit.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LangTable mdxType="LangTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      